@font-face {
  font-family: 'Charter';
  src:
    local('Charter'),
    url('./fonts/charter_regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Charter';
  src:
    local('Charter Italic'),
    url('./fonts/charter_italic.woff') format('woff');
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Charter';
  src:
    local('Charter Bold'),
    url('./fonts/charter_bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Charter';
  src:
    local('Charter Bold Italic'),
    url('./fonts/charter_bold_italic.woff') format('woff');
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
